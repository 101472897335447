export default {
	form: (a, related_entity) => {
		return {
			name: "<span class='mr-1'>Modifiko</span> <b>" + a.current.name + "</b>",
			submit: {
				label: 'Modifiko', 
					action: 'submitAccessory'
			},
			delete: {
				guid: a.current.guid,
				model: 'Accessory',
				api: 'accessory/delete/' + related_entity
			},
			fields: [
				[
					{ 
						value: a.current.code, 
						name: 'code', 
						label: 'Kodi', 
						type: 'text-field', 
						unique: {
							model: 'accessory', 
							field: 'code',
							where: [
								{
									column: 'related_entity',
									condition: '=',
									value: related_entity 
								},
								{
									column: 'guid',
									condition: '!=',
									value: a.current.guid 
								}
							]
						},  
						left_icon: 'fa-solid fa-hashtag', 
						style: "min-width: 45%",
						required: true,
					},
					{
						value: a.current.name, 
						name: 'name', 
						label: 'Emri', 
						type: 'text-field', 
						style: "min-width: 45%",
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme'
					},
					{
						value: a.current.active, 
						name: 'active', 
						label: 'Aktiv', 
						type: 'checkbox-field', 
						style: "min-width: 2%",
						unique: true, 
					},
				],
				{ 
					value: a.current.description, 
					name: 'description', 
					label: 'Pershkrim', 
					type: 'textarea-field',
				}
			]
		};
	} 
}
