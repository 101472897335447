<template>
  <div class="columns">
    <div class="column is-4">
      Aksesor {{ $root.modules[related_entity].title }}
      <a @click.prevent="masterCategory()">+ shto</a>
      <Tree v-if="accessories.list" :items="accessories.list" :flat="true" />
    </div>
    <div class="column is-4">
      <DaForm
        v-if="form"
        @submitted="submitAccessory"
        @deleted="deleteAccessory"
        :form="form"
        @close="form = null" :closes_form="true"
      >
      </DaForm>
    </div>
  </div>
</template>

<script>
import Tree from "@/components/Tree.vue";
import DaForm from "@/components/daform/index.vue";
import createAccessory from "./forms/create";
import editAccessory from "./forms/edit";
import { useAccessoryStore } from "@/stores/accessory";
import AccessoriesServices from "@/services/Accessory";
import helpers from "@/helpers.js";
import permissionMixin from '@/mixins/permissions-mixin.js'

export default {
  mixins: [permissionMixin],
  components: {
    Tree,
    DaForm,
  },
  setup() {
    let accessories = useAccessoryStore()
    return {
      accessories
    }
  },
  data() {
    return {
      form: null,
      parent_accessory: -1,
      related_entity: "",
    };
  },
  watch: {
    "$route.params"() {
      this.related_entity = this.$route.params.related_entity;
      if (this.related_entity) {
        this.getInitialData();
      }
    },
    "accessories.current"() {
      if (this.accessories.mode == "add"){
        if ( 
            (this.am_allowed('product.can_create_product_accessory') && this.$route.path == '/accessories/products')
        ){
          this.createAccessory(
            this.accessories.current.guid ? this.accessories.current.guid : null
          );
        } else {
          this.$toast.error('Ju nuk keni te drejta per te krijuar nje aksesor.')
        }

      } 
      if (this.accessories.mode == "edit") {
        if (
             (this.am_allowed('product.can_edit_product_accessory') && this.$route.path == '/accessories/products')
           )
        {
          this.editAccessory(this.accessories.current.guid);
        } else {
          this.$toast.error('Ju nuk keni te drejta per te modifikuar nje aksesor.')
        }

      }
    },
  },
  methods: {
    async getInitialData() {
      if (!this.related_entity) return;

      this.accessories.flat_list = [];
      this.accessories.flat_list = await AccessoriesServices.getAccessories(
        this.related_entity
      );
      this.accessories.list = [];
      this.accessories.list = await AccessoriesServices.getTreeAccessories(
        this.accessories.flat_list,
        "id"
      );
    },
    deleteAccessory(f) {
      this.accessories.flat_list = [
        ...this.accessories.flat_list.filter((a) => {
          var found = false;
          f.map((ff) => {
            if (ff.descendant == a.guid) found = true;
          });
          return !found;
        }),
      ];
      this.accessories.list = helpers.toTree(this.accessories.flat_list, "id");
    },
    async submitAccessory(f) {
      // set parent accessory
      f = {
        ...f,
        parent_id: this.parent_accessory,
        guid:
          this.accessories.current.guid &&
          this.accessories.current.guid != this.parent_accessory
            ? this.accessories.current.guid
            : null,
      };
      // send to api

      let accessory_process = await AccessoriesServices.getAccessoryProcess(
        f,
        this.accessories.mode,
        this.related_entity
      );


      var notify_text = "";

      if (this.accessories.mode == "add") {
        var new_accessory_process = {
          ...accessory_process,
          id: accessory_process.guid,
          label: accessory_process.name,
          collapsed: true,
        };
        this.accessories.flat_list.push(new_accessory_process);
        this.accessories.list = [];
        this.accessories.list = await AccessoriesServices.getTreeAccessories(
          this.accessories.flat_list,
          "id"
        );
        notify_text = "Aksesori u krijua me sukses!";
      } else if (this.accessories.mode == "edit") {
        this.accessories.flat_list = [
          ...this.accessories.flat_list.map((a) => {
            if(a.guid == accessory_process.guid) a = accessory_process
            return a
          }),
        ];
        
        notify_text = "Aksesori u modifikua me sukses!";
      }

      this.$toast.success(notify_text);
      this.form = null;
    },
    async editAccessory() {
      this.form = {loading: true};
      this.parent_accessory = this.accessories.current.parent_id
   
      setTimeout(() => {
          this.form = editAccessory.form(this.accessories, this.related_entity)
      });
    },
    async createAccessory(parent_accessory) {
      this.form = {loading: true};
      // set parent category
      this.parent_accessory = parent_accessory;
    
      setTimeout(() => {
          this.form = {
            ...createAccessory.form(this.accessories, this.related_entity),
          };
      });
    },
    masterCategory() {
      if (
          (this.am_allowed('product.can_create_product_accessory') && this.$route.path == '/accessories/products')
      ){
        this.accessories.current = {};
        this.accessories.mode = "add";
      } else {
        this.$toast.error('Ju nuk keni te drejta per te krijuar nje aksesor.')
      }
    },
  },
  async created() {
    if(
      this.related_entity == 'products' && !this.am_allowed('product.can_see_product_accessory')
    ){
      window.location.href = '/home'
    }
    this.related_entity = this.$route.params.related_entity;
    this.getInitialData();
  },
};
</script>
