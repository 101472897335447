export default {
	form: (a, related_entity) => { 
		var ob = {} 
		ob = {
			name: "Krijo" + (a.current.guid ? " Sub" : "") 
				+" Aksesor" + (a.current.name ? ": <i>" + a.current.name +"</i>" : ''),
			submit: {
				label: 'Krijo', 
				action: 'submitAccessory'
			},
			fields: [
				[
					{ 
						value: '', 
						name: 'code', 
						label: 'Kodi', 
						type: 'text-field', 
						unique: {
							model: 'accessory', 
							field: 'code',
							where: [
								{
									column: 'related_entity',
									condition: '=',
									value: related_entity 
								}
							]
						}, 
						left_icon: 'fa-solid fa-hashtag', 
						style: "min-width: 45%",
						required: true,
					},
					{
						value: '', 
						name: 'name', 
						label: 'Emri', 
						type: 'text-field', 
						style: "min-width: 45%",
						required: true,
						required_text: 'Kjo fushe eshte e detyrueshme'
					},
					{
						value: 0, 
						name: 'active', 
						label: 'Aktiv', 
						style: "min-width: 2%",
						type: 'checkbox-field', 
					},
				],
				{ 
					value: '', 
					name: 'description', 
					label: 'Pershkrim', 
					type: 'textarea-field',
				},
			]
		} 
		return ob
	}
}
